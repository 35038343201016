import { CALENDAR_PERMISSIONS_KEY, PERMISSIONS_KEY } from "common/utility";
import { USER_ROLE } from ".";
import { MenuItemTypes } from "../interfaces";
import { CALENDAR_PERMISSION_KEYS, PERMISSION_KEYS } from "common/permissionKeys";
import DriveIcon from "components/Icons/DriveIcon";

const MENU_ITEMS: MenuItemTypes[] = [
  {
    key: "dashboards",
    label: "Dashboard",
    isTitle: false,
    icon: "home",
    url: "/dashboard/admin",
    allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.VENDOR, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER],
  },
  {
    key: "us-teams",
    label: "Teams",
    isTitle: false,
    icon: "users",
    url: "/users/teams",
    allowedUsers: [USER_ROLE.SUPERADMIN],
  },
  {
    key: "us-vendor",
    label: "Vendors",
    isTitle: false,
    icon: "users",
    url: "/vendors",
    allowedUsers: [USER_ROLE.INDEPENDENT_USER],
  },
  {
    key: "us-clients",
    label: "Clients",
    isTitle: false,
    icon: "users",
    url: "/user-clients",
    allowedUsers: [USER_ROLE.INDEPENDENT_VENDOR],
  },
  {
    key: "us-user-teams",
    label: "Teams",
    isTitle: false,
    icon: "users",
    url: "/users/team",
    allowedUsers: [USER_ROLE.USER],
    checkInUserState: {
      key: "IsGroupLeader",
      value: true,
    },
  },
  {
    key: "us-static-data",
    label: "Static Data",
    isTitle: false,
    icon: "code",
    url: "/static-data",
    allowedUsers: [USER_ROLE.SUPERADMIN],
  },
  {
    key: "us-client-approvals",
    label: "Client Approvals",
    isTitle: false,
    icon: "users",
    url: "/client-approvals",
    allowedUsers: [USER_ROLE.SUPERADMIN],
  },
  {
    key: "us-places",
    label: "Manage Places",
    isTitle: false,
    icon: "globe",
    url: "/places/countries",
    allowedUsers: [USER_ROLE.SUPERADMIN],
  },
  {
    key: "us-track-activity",
    label: "Track Activity",
    isTitle: false,
    icon: "activity",
    url: "/track-activity",
    allowedUsers: [USER_ROLE.ADMIN],
  },
  {
    key: "users",
    label: "Users",
    isTitle: false,
    icon: "users",
    allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.SUPERADMIN],
    url: "/users",
    children: [
      {
        key: "us-manage-user",
        label: "Manage Users",
        url: "/users/manageuser",
        parentKey: "users",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR,],
      },
      {
        key: "us-teams-admin",
        label: "Teams",
        url: "/users/team",
        parentKey: "users",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR,],
      },
      {
        key: "us-vendors",
        label: "Vendors",
        url: "/users/vendors",
        parentKey: "users",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN],
        applicablePermission: {
          accessKey: PERMISSIONS_KEY,
          permissionKey: PERMISSION_KEYS.VIEW_MANAGE_VENDOR
        },
      },
    ],
  },
  {
    key: "calendar",
    label: "Calendar",
    isTitle: false,
    icon: "calendar",
    allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.USER, USER_ROLE.VENDOR, USER_ROLE.INDEPENDENT_USER],
    url: "/calendar",
    children: [
      {
        key: "unified-calendar",
        label: "Unified",
        url: "/unified-calendar",
        parentKey: "calendar",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.USER],
      },
      {
        key: "global-calendar",
        label: "Organizational",
        url: "/calendar",
        parentKey: "calendar",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.USER],
        applicablePermission: {
          accessKey: CALENDAR_PERMISSIONS_KEY,
          permissionKey: CALENDAR_PERMISSION_KEYS.VIEW_ORGANIZATION_CALENDAR
        }
      },
      {
        key: "team-calendar",
        label: "Team",
        url: "/team-calendar",
        parentKey: "calendar",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.USER],
        applicablePermission: {
          accessKey: CALENDAR_PERMISSIONS_KEY,
          permissionKey: CALENDAR_PERMISSION_KEYS.VIEW_TEAM_CALENDAR
        }
      },
      {
        key: "personal-calendar",
        label: "Personal",
        url: "/personal-calendar",
        parentKey: "calendar",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.USER, USER_ROLE.INDEPENDENT_USER],
      },
      {
        key: "job-scheduler-calendar",
        label: "Job Scheduler",
        url: "/job-scheduler",
        parentKey: "calendar",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.USER],
        applicablePermission: {
          accessKey: CALENDAR_PERMISSIONS_KEY,
          permissionKey: CALENDAR_PERMISSION_KEYS.VIEW_JOB_SCHEDULER
        }
      },
    ],
  },
  {
    key: "buildings-location",
    label: "Buildings/Locations",
    isTitle: false,
    icon: "home",
    allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
    url: "/buildings-location",
  },
  {
    key: "response-requests",
    label: "Response Requests",
    isTitle: false,
    icon: "shuffle",
    allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.USER, USER_ROLE.VENDOR],
    url: "/response-requests",
  },
  // {
  // 	key: "user-assets-view",
  // 	label: "Assets",
  // 	isTitle: false,
  // 	icon: "package",
  // 	allowedUsers: [USER_ROLE.USER],
  // 	url: '/assets',
  // },
  {
    key: "assets",
    label: "Assets",
    isTitle: false,
    icon: "package",
    allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
    url: '/assets',
    children: [],
  },
  {
    key: "telematics",
    label: "Telematics",
    isTitle: false,
    icon: "",
    CustomIcon: DriveIcon,
    allowedUsers: [USER_ROLE.ADMIN],
    url: "/telematics",
    children: [
      {
        key: "tel-verizon",
        label: "Verizon",
        url: "/verizon",
        parentKey: "telematics",
        allowedUsers: [USER_ROLE.ADMIN],
      },
      {
        key: "tel-fleet-complete",
        label: "Fleet Complete",
        url: "/fleet-complete",
        parentKey: "telematics",
        allowedUsers: [USER_ROLE.ADMIN],
      },
    ],
  },
  {
    key: "maintenance",
    label: "Maintenance",
    isTitle: false,
    icon: "tool",
    allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.VENDOR, USER_ROLE.USER],
    children: [
      {
        key: "mt-schedule-maintenance",
        label: "Maintenance Schedule",
        url: "/maintenance/scheduled-maintenance",
        parentKey: "maintenance",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
      },
      {
        key: "mt-tasks-maintenance",
        label: "Maintenance Tasks",
        url: "/maintenance/maintenance-tasks",
        parentKey: "maintenance",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
      },
      {
        key: "mt-tickets-wos-list",
        label: "Tickets/WOs",
        url: "/maintenance/tickets-list",
        parentKey: "maintenance",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.VENDOR, USER_ROLE.USER],
      },
    ],
  },
  {
    key: "vendor-clients",
    label: "User Requests",
    isTitle: false,
    icon: "shuffle",
    allowedUsers: [USER_ROLE.VENDOR],
    url: "/vendor-clients",
  },
  {
    key: "management",
    label: "Management",
    isTitle: false,
    icon: "grid",
    allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
    url: "/management",
    children: [
      {
        key: "mg-inspections",
        label: "Inspections",
        url: "/management/inspections",
        parentKey: "management",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
      },

      {
        key: "mg-check-in-outs",
        label: "Check In/Outs",
        url: "/management/check-InOuts",
        parentKey: "management",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
      },
    ],
  },
  {
    key: "project-management",
    label: "Project Management",
    isTitle: false,
    icon: "grid",
    url: "/project-management",
    allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.USER],
  },
  {
    key: "settings",
    label: "Settings",
    isTitle: false,
    icon: "settings",
    url: "/",
    allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN],
    children: [
      {
        key: "st-manage-menu-items",
        label: "Menu Items",
        url: "/settings/menu-items",
        parentKey: "settings",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.VENDOR],
      },
      {
        key: "st-manage-asset-types",
        label: "Asset Types",
        url: "/settings/asset-types",
        parentKey: "settings",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.VENDOR],
      },
      {
        key: "st-manage-inspection-forms",
        label: "Inspection Forms",
        url: "/settings/inspection-forms",
        parentKey: "settings",
        allowedUsers: [USER_ROLE.ADMIN],
      },
      {
        key: "st-manage-categories",
        label: "Categories",
        url: "/settings/categories",
        parentKey: "settings",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.VENDOR],
      },
      {
        key: "st-manage-departments",
        label: "Departments",
        url: "/settings/departments",
        parentKey: "settings",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.VENDOR],
      },
      {
        key: "st-manage-roles",
        label: "Roles",
        url: "/settings/roles",
        parentKey: "settings",
        allowedUsers: [USER_ROLE.ADMIN],
      },
      {
        key: "st-manage-reason-purpose",
        label: "Reason/Purpose",
        url: "/settings/reason-purpose",
        parentKey: "settings",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.VENDOR],
      },
      {
        key: "st-manage-company-information",
        label: "Company Information",
        url: "/settings/company-information",
        parentKey: "settings",
        allowedUsers: [USER_ROLE.ADMIN],
      },
      {
        key: "st-manage-manage-inspection",
        label: "Manage Inspection",
        url: "/settings/manage-inspection",
        parentKey: "settings",
        allowedUsers: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN, USER_ROLE.VENDOR],
      },
      {
        key: "st-manage-type-of-organization",
        label: "Type of Organization",
        url: "/settings/type-of-organization",
        parentKey: "settings",
        allowedUsers: [USER_ROLE.ADMIN],
      },
      // {
      // 	key: "st-manage-notification",
      // 	label: "Notification",
      // 	url: "/settings/notification",
      // 	parentKey: "settings",
      // 	allowedUsers: [
      // 	USER_ROLE.ADMIN,
      // 	USER_ROLE.SUPERADMIN,
      // 	USER_ROLE.VENDOR,
      // ],
      // },
      {
        key: "st-manage-timezones",
        label: "Timezone Details",
        url: "/settings/timezone-details",
        parentKey: "settings",
        allowedUsers: [USER_ROLE.SUPERADMIN],
      },
      {
        key: "st-manage-vendor-services",
        label: "Vendor Services",
        url: "/settings/vendor-services",
        parentKey: "settings",
        allowedUsers: [USER_ROLE.SUPERADMIN],
      },
    ],
  },
];

const HORIZONTAL_MENU_ITEMS: MenuItemTypes[] = MENU_ITEMS;
const TWO_COl_MENU_ITEMS: MenuItemTypes[] = MENU_ITEMS;

// const HORIZONTAL_MENU_ITEMS: MenuItemTypes[] = [
// 	{
// 		key: "dashboards",
// 		icon: "home",
// 		label: "Dashboards",
// 		isTitle: true,
// 		children: [
// 			{
// 				key: "ds-ecommerce",
// 				label: "Ecommerce",
// 				url: "/dashboard/ecommerce",
// 				parentKey: "dashboards",
// 			},
// 			{
// 				key: "ds-analytics",
// 				label: "Analytics",
// 				url: "/dashboard/analytics",
// 				parentKey: "dashboards",
// 			},
// 		],
// 	},
// 	{
// 		key: "apps",
// 		icon: "layers",
// 		label: "Apps",
// 		isTitle: true,
// 		children: [
// 			{
// 				key: "apps-calendar",
// 				label: "Calendar",
// 				isTitle: false,
// 				url: "/apps/calendar",
// 				parentKey: "apps",
// 			},
// 			{
// 				key: "apps-chat",
// 				label: "Chat",
// 				isTitle: false,
// 				url: "/apps/chat",
// 				parentKey: "apps",
// 			},
// 			{
// 				key: "apps-email",
// 				label: "Email",
// 				isTitle: false,
// 				parentKey: "apps",
// 				children: [
// 					{
// 						key: "email-inbox",
// 						label: "Inbox",
// 						url: "/apps/email/inbox",
// 						parentKey: "apps-email",
// 					},
// 					{
// 						key: "email-read-email",
// 						label: "Read Email",
// 						url: "/apps/email/details",
// 						parentKey: "apps-email",
// 					},
// 					{
// 						key: "email-compose-email",
// 						label: "Compose Email",
// 						url: "/apps/email/compose",
// 						parentKey: "apps-email",
// 					},
// 				],
// 			},
// 			{
// 				key: "apps-projects",
// 				label: "Projects",
// 				isTitle: false,
// 				parentKey: "apps",
// 				children: [
// 					{
// 						key: "project-list",
// 						label: "List",
// 						url: "/apps/projects/list",
// 						parentKey: "apps-projects",
// 					},
// 					{
// 						key: "project-details",
// 						label: "Details",
// 						url: "/apps/projects/details",
// 						parentKey: "apps-projects",
// 					},
// 				],
// 			},
// 			{
// 				key: "apps-tasks",
// 				label: "Tasks",
// 				isTitle: false,
// 				parentKey: "apps",
// 				children: [
// 					{
// 						key: "task-list",
// 						label: "List",
// 						url: "/apps/tasks/list",
// 						parentKey: "apps-tasks",
// 					},
// 					{
// 						key: "task-kanban",
// 						label: "Kanban Board",
// 						url: "/apps/tasks/kanban",
// 						parentKey: "apps-tasks",
// 					},
// 				],
// 			},
// 			{
// 				key: "apps-file-manager",
// 				label: "File Manager",
// 				isTitle: false,
// 				url: "/apps/file-manager",
// 				parentKey: "apps",
// 			},
// 		],
// 	},
// 	{
// 		key: "components",
// 		icon: "briefcase",
// 		label: "Components",
// 		isTitle: true,
// 		children: [
// 			{
// 				key: "ui-elements",
// 				label: "UI Elements",
// 				isTitle: false,
// 				url: "/components/ui-elements",
// 				parentKey: "components",
// 			},
// 			{
// 				key: "widgets",
// 				label: "Widgets",
// 				isTitle: false,
// 				url: "/components/widgets",
// 				parentKey: "components",
// 			},
// 			{
// 				key: "forms",
// 				label: "Forms",
// 				isTitle: false,
// 				parentKey: "components",
// 				children: [
// 					{
// 						key: "form-basic",
// 						label: "Basic Elements",
// 						url: "/forms/basic",
// 						parentKey: "forms",
// 					},
// 					{
// 						key: "form-advanced",
// 						label: "Advanced",
// 						url: "/forms/advanced",
// 						parentKey: "forms",
// 					},
// 					{
// 						key: "form-validation",
// 						label: "Validation",
// 						url: "/forms/validation",
// 						parentKey: "forms",
// 					},
// 					{
// 						key: "form-wizard",
// 						label: "Wizard",
// 						url: "/forms/wizard",
// 						parentKey: "forms",
// 					},
// 					{
// 						key: "form-editors",
// 						label: "Editors",
// 						url: "/forms/editors",
// 						parentKey: "forms",
// 					},
// 					{
// 						key: "form-upload",
// 						label: "File Uploads",
// 						url: "/forms/upload",
// 						parentKey: "forms",
// 					},
// 				],
// 			},
// 			{
// 				key: "charts",
// 				label: "Charts",
// 				isTitle: false,
// 				url: "/components/charts",
// 				parentKey: "components",
// 			},
// 			{
// 				key: "tables",
// 				label: "Tables",
// 				isTitle: false,
// 				parentKey: "components",
// 				children: [
// 					{
// 						key: "table-basic",
// 						label: "Basic Tables",
// 						url: "/tables/basic",
// 						parentKey: "tables",
// 					},
// 					{
// 						key: "table-advanced",
// 						label: "Advanced Tables",
// 						url: "/tables/advanced",
// 						parentKey: "tables",
// 					},
// 				],
// 			},
// 			{
// 				key: "icons",
// 				label: "Icons",
// 				isTitle: false,
// 				parentKey: "components",
// 				children: [
// 					{
// 						key: "icon-unicons",
// 						label: "Unicons",
// 						url: "/icons/unicons",
// 						parentKey: "icons",
// 					},
// 					{
// 						key: "icon-feather",
// 						label: "Feather",
// 						url: "/icons/feather",
// 						parentKey: "icons",
// 					},
// 					{
// 						key: "icon-bootstrap",
// 						label: "Bootstrap",
// 						url: "/icons/bootstrap",
// 						parentKey: "icons",
// 					},
// 				],
// 			},
// 			{
// 				key: "maps",
// 				label: "Maps",
// 				isTitle: false,
// 				parentKey: "components",
// 				children: [
// 					{
// 						key: "maps-googlemaps",
// 						label: "Google Maps",
// 						url: "/maps/googlemaps",
// 						parentKey: "maps",
// 					},
// 					{
// 						key: "maps-vectormaps",
// 						label: "Vector Maps",
// 						url: "/maps/vectormaps",
// 						parentKey: "maps",
// 					},
// 				],
// 			},
// 		],
// 	},
// 	{
// 		key: "extra-pages",
// 		label: "Pages",
// 		isTitle: false,
// 		icon: "file-text",
// 		children: [
// 			{
// 				key: "page-starter",
// 				label: "Starter",
// 				url: "/pages/starter",
// 				parentKey: "extra-pages",
// 			},
// 			{
// 				key: "page-profile",
// 				label: "Profile",
// 				url: "/pages/profile",
// 				parentKey: "extra-pages",
// 			},
// 			{
// 				key: "page-activity",
// 				label: "Activity",
// 				url: "/pages/activity",
// 				parentKey: "extra-pages",
// 			},
// 			{
// 				key: "page-invoice",
// 				label: "Invoice",
// 				url: "/pages/invoice",
// 				parentKey: "extra-pages",
// 			},
// 			{
// 				key: "page-pricing",
// 				label: "Pricing",
// 				url: "/pages/pricing",
// 				parentKey: "extra-pages",
// 			},
// 			{
// 				key: "page-maintenance",
// 				label: "Maintenance",
// 				url: "/maintenance",
// 				target: "_blank",
// 				parentKey: "extra-pages",
// 			},
// 			{
// 				key: "page-error-404",
// 				label: "Error - 404",
// 				url: "/error-404",
// 				parentKey: "extra-pages",
// 			},
// 			{
// 				key: "page-error-500",
// 				label: "Error - 500",
// 				url: "/error-500",
// 				parentKey: "extra-pages",
// 			},
// 		],
// 	},
// ];

// const TWO_COl_MENU_ITEMS: MenuItemTypes[] = [
// 	{
// 		key: "dashboards",
// 		label: "Dashboards",
// 		isTitle: true,
// 		icon: "home",
// 		children: [
// 			{
// 				key: "ds-ecommerce",
// 				label: "Ecommerce",
// 				url: "/dashboard/ecommerce",
// 				parentKey: "dashboards",
// 			},
// 			{
// 				key: "ds-analytics",
// 				label: "Analytics",
// 				url: "/dashboard/analytics",
// 				parentKey: "dashboards",
// 			},
// 		],
// 	},
// 	{
// 		key: "apps",
// 		icon: "grid",
// 		label: "Apps",
// 		isTitle: true,
// 		children: [
// 			{
// 				key: "apps-calendar",
// 				label: "Calendar",
// 				isTitle: false,
// 				icon: "calendar",
// 				url: "/apps/calendar",
// 				parentKey: "apps",
// 			},
// 			{
// 				key: "apps-chat",
// 				label: "Chat",
// 				isTitle: false,
// 				icon: "message-square",
// 				url: "/apps/chat",
// 				parentKey: "apps",
// 			},
// 			{
// 				key: "apps-email",
// 				label: "Email",
// 				isTitle: false,
// 				icon: "mail",
// 				parentKey: "apps",
// 				children: [
// 					{
// 						key: "email-inbox",
// 						label: "Inbox",
// 						url: "/apps/email/inbox",
// 						parentKey: "apps-email",
// 					},
// 					{
// 						key: "email-read-email",
// 						label: "Read Email",
// 						url: "/apps/email/details",
// 						parentKey: "apps-email",
// 					},
// 					{
// 						key: "email-compose-email",
// 						label: "Compose Email",
// 						url: "/apps/email/compose",
// 						parentKey: "apps-email",
// 					},
// 				],
// 			},
// 			{
// 				key: "apps-projects",
// 				label: "Projects",
// 				isTitle: false,
// 				icon: "briefcase",
// 				parentKey: "apps",
// 				children: [
// 					{
// 						key: "project-list",
// 						label: "List",
// 						url: "/apps/projects/list",
// 						parentKey: "apps-projects",
// 					},
// 					{
// 						key: "project-details",
// 						label: "Details",
// 						url: "/apps/projects/details",
// 						parentKey: "apps-projects",
// 					},
// 				],
// 			},
// 			{
// 				key: "apps-tasks",
// 				label: "Tasks",
// 				isTitle: false,
// 				icon: "clipboard",
// 				parentKey: "apps",
// 				children: [
// 					{
// 						key: "task-list",
// 						label: "List",
// 						url: "/apps/tasks/list",
// 						parentKey: "apps-tasks",
// 					},
// 					{
// 						key: "task-kanban",
// 						label: "Kanban Board",
// 						url: "/apps/tasks/kanban",
// 						parentKey: "apps-tasks",
// 					},
// 				],
// 			},
// 			{
// 				key: "apps-file-manager",
// 				label: "File Manager",
// 				isTitle: false,
// 				icon: "file-plus",
// 				url: "/apps/file-manager",
// 				parentKey: "apps",
// 			},
// 		],
// 	},
// 	{
// 		key: "extra-pages",
// 		icon: "file-text",
// 		label: "Pages",
// 		isTitle: true,
// 		children: [
// 			{
// 				key: "page-starter",
// 				label: "Starter",
// 				url: "/pages/starter",
// 				parentKey: "extra-pages",
// 			},
// 			{
// 				key: "page-profile",
// 				label: "Profile",
// 				url: "/pages/profile",
// 				parentKey: "extra-pages",
// 			},
// 			{
// 				key: "page-activity",
// 				label: "Activity",
// 				url: "/pages/activity",
// 				parentKey: "extra-pages",
// 			},
// 			{
// 				key: "page-invoice",
// 				label: "Invoice",
// 				url: "/pages/invoice",
// 				parentKey: "extra-pages",
// 			},
// 			{
// 				key: "page-pricing",
// 				label: "Pricing",
// 				url: "/pages/pricing",
// 				parentKey: "extra-pages",
// 			},
// 			{
// 				key: "page-maintenance",
// 				label: "Maintenance",
// 				url: "/maintenance",
// 				target: "_blank",
// 				parentKey: "extra-pages",
// 			},
// 			{
// 				key: "page-error-404",
// 				label: "Error - 404",
// 				url: "/error-404",
// 				parentKey: "extra-pages",
// 			},
// 			{
// 				key: "page-error-500",
// 				label: "Error - 500",
// 				url: "/error-500",
// 				parentKey: "extra-pages",
// 			},
// 		],
// 	},
// 	{
// 		key: "components",
// 		icon: "package",
// 		label: "Components",
// 		isTitle: true,
// 		children: [
// 			{
// 				key: "base-ui",
// 				label: "UI Elements",
// 				isTitle: false,
// 				icon: "package",
// 				url: "/components/ui-elements",
// 				parentKey: "components",
// 			},
// 			{
// 				key: "icons",
// 				label: "Icons",
// 				isTitle: false,
// 				icon: "cpu",
// 				parentKey: "components",
// 				children: [
// 					{
// 						key: "icon-unicons",
// 						label: "Unicons",
// 						url: "/icons/unicons",
// 						parentKey: "icons",
// 					},
// 					{
// 						key: "icon-feather",
// 						label: "Feather",
// 						url: "/icons/feather",
// 						parentKey: "icons",
// 					},
// 					{
// 						key: "icon-bootstrap",
// 						label: "Bootstrap",
// 						url: "/icons/bootstrap",
// 						parentKey: "icons",
// 					},
// 				],
// 			},
// 			{
// 				key: "charts",
// 				label: "Charts",
// 				isTitle: false,
// 				icon: "bar-chart-2",
// 				url: "/components/charts",
// 				parentKey: "components",
// 			},
// 			{
// 				key: "forms",
// 				label: "Forms",
// 				isTitle: false,
// 				icon: "bookmark",
// 				parentKey: "components",
// 				children: [
// 					{
// 						key: "form-basic",
// 						label: "Basic Elements",
// 						url: "/forms/basic",
// 						parentKey: "forms",
// 					},
// 					{
// 						key: "form-advanced",
// 						label: "Advanced",
// 						url: "/forms/advanced",
// 						parentKey: "forms",
// 					},
// 					{
// 						key: "form-validation",
// 						label: "Validation",
// 						url: "/forms/validation",
// 						parentKey: "forms",
// 					},
// 					{
// 						key: "form-wizard",
// 						label: "Wizard",
// 						url: "/forms/wizard",
// 						parentKey: "forms",
// 					},
// 					{
// 						key: "form-editors",
// 						label: "Editors",
// 						url: "/forms/editors",
// 						parentKey: "forms",
// 					},
// 					{
// 						key: "form-upload",
// 						label: "File Uploads",
// 						url: "/forms/upload",
// 						parentKey: "forms",
// 					},
// 				],
// 			},
// 			{
// 				key: "tables",
// 				label: "Tables",
// 				isTitle: false,
// 				icon: "grid",
// 				parentKey: "components",
// 				children: [
// 					{
// 						key: "table-basic",
// 						label: "Basic Tables",
// 						url: "/tables/basic",
// 						parentKey: "tables",
// 					},
// 					{
// 						key: "table-advanced",
// 						label: "Advanced Tables",
// 						url: "/tables/advanced",
// 						parentKey: "tables",
// 					},
// 				],
// 			},
// 			{
// 				key: "maps",
// 				label: "Maps",
// 				isTitle: false,
// 				icon: "map",
// 				parentKey: "components",
// 				children: [
// 					{
// 						key: "maps-googlemaps",
// 						label: "Google Maps",
// 						url: "/maps/googlemaps",
// 						parentKey: "maps",
// 					},
// 					{
// 						key: "maps-vectormaps",
// 						label: "Vector Maps",
// 						url: "/maps/vectormaps",
// 						parentKey: "maps",
// 					},
// 				],
// 			},
// 			{
// 				key: "menu-levels",
// 				label: "Menu Levels",
// 				isTitle: false,
// 				icon: "share-2",
// 				parentKey: "components",
// 				children: [
// 					{
// 						key: "menu-levels-1-1",
// 						label: "Level 1.1",
// 						url: "/",
// 						parentKey: "menu-levels",
// 						children: [
// 							{
// 								key: "menu-levels-2-1",
// 								label: "Level 2.1",
// 								url: "/",
// 								parentKey: "menu-levels-1-1",
// 								children: [
// 									{
// 										key: "menu-levels-3-1",
// 										label: "Level 3.1",
// 										url: "/",
// 										parentKey: "menu-levels-2-1",
// 									},
// 									{
// 										key: "menu-levels-3-2",
// 										label: "Level 3.2",
// 										url: "/",
// 										parentKey: "menu-levels-2-1",
// 									},
// 								],
// 							},
// 							{
// 								key: "menu-levels-2-2",
// 								label: "Level 2.2",
// 								url: "/",
// 								parentKey: "menu-levels-1-1",
// 							},
// 						],
// 					},
// 					{
// 						key: "menu-levels-1-2",
// 						label: "Level 1.2",
// 						url: "/",
// 						parentKey: "menu-levels",
// 					},
// 				],
// 			},
// 		],
// 	},
// 	{
// 		key: "widgets",
// 		label: "Widgets",
// 		isTitle: false,
// 		icon: "gift",
// 		url: "/components/widgets",
// 	},
// ];

export { MENU_ITEMS, TWO_COl_MENU_ITEMS, HORIZONTAL_MENU_ITEMS };
