import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// layout constants
import { LayoutTypes } from "../constants/layout";

// strore
import { RootState } from "../redux/store";

// All layouts containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import HorizontalLayout from "../layouts/Horizontal/";
import TwoColumnLayout from "../layouts/TwoColumn/";

import {
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
} from "./index";

import { APICore } from "../helpers/api/apiCore";
import { useUser } from "../hooks";
import Root from "./Root";
import QRLanding from "pages/auth/QRLanding";
import VendorRegister from "pages/auth/VendorRegister";
import SnippetContentPage from "pages/auth/SnippetContentPage";
import SignUp from "pages/auth/SignUp";
import VerifyOTP from "pages/auth/VerifyOTP";
import RegisterConfirm from "pages/auth/RegisterConfirm";
import {
  AUTH_SESSION_KEY,
  CALENDAR_PERMISSIONS_KEY,
  INTEGRATIONS_KEY,
  PERMISSIONS_KEY,
  ReadLocalStorage,
  SetLocalStorage,
  getPlayerId,
} from "common/utility";
import { getRolePermissions } from "helpers/api/role";
import moment from "moment";
import { getUserPermissions } from "helpers/api/user";
import { getAllIntegrationTypes } from "helpers/api/integrationType";
import { USER_ROLE } from "constants/index";
import PersonalAccountSignup from "pages/auth/PersonalAccountSignup";

interface RoutesProps {}

const AllRoutes = (props: RoutesProps) => {
  const { user: user1, layout } = useSelector((state: RootState) => ({
    user: state.Auth.user,
    layout: state.Layout,
  }));
  const location = useLocation();
  const { user } = useUser();

  const getLayout = () => {
    let layoutCls = TwoColumnLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case LayoutTypes.LAYOUT_VERTICAL:
        layoutCls = VerticalLayout;
        break;
      default:
        layoutCls = TwoColumnLayout;
        break;
    }
    return layoutCls;
  };

  let Layout = getLayout();
  const api = new APICore();

  useEffect(() => {
    // GetPlayerId();
  }, [user1]);

  const GetPlayerId = async () => {
    try {
      const playerid = await getPlayerId();
      console.log(`playerid ${playerid}`);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const GetPermissions = async () => {
    try {
      const userdata: any = ReadLocalStorage(AUTH_SESSION_KEY);
      if (userdata) {
        const RoleId = userdata.RoleId;
        const response = await getRolePermissions({
          roleId: RoleId,
        });
        if (response.StatusCode === 200) {
          const data = response.Data;
          if (data[0]) {
            let currentTime = moment();
            let futureTime = currentTime.add(30, "seconds");
            SetLocalStorage(
              PERMISSIONS_KEY,
              JSON.stringify({
                data: data[0],
                expiryTime: futureTime,
              })
            );
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const GetIntegrations = async () => {
    try {
      const response = await getAllIntegrationTypes({
        pageNumber: 1,
        pageSize: 100,
        query: "all",
      });
      if (response.StatusCode === 200) {
        const data = response.Data.Items;
        if (data[0]) {
          let currentTime = moment();
          let futureTime = currentTime.add(30, "seconds");
          SetLocalStorage(
            INTEGRATIONS_KEY,
            JSON.stringify({
              data: data,
              expiryTime: futureTime,
            })
          );
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const GetCalendarPermissions = async () => {
    try {
      const userdata: any = ReadLocalStorage(AUTH_SESSION_KEY);
      if (userdata) {
        const UserId = userdata.Id;
        const calendarPermissionsParams = {
          pageNo: 1,
          pageSize: 100,
          userId: UserId,
          query: "all",
        };
        const result = await getUserPermissions(calendarPermissionsParams);
        if (result.StatusCode === 200) {
          const data = result.Data.Items;
          let currentTime = moment();
          let futureTime = currentTime.add(30, "seconds");
          SetLocalStorage(
            CALENDAR_PERMISSIONS_KEY,
            JSON.stringify({
              data: data,
              expiryTime: futureTime,
            })
          );
        }
      }
      // setCalendarPermissions
    } catch (error) {
      console.log("error", error);
    }
  };

  const checkExpiry = () => {
    try {
      let permissionData: any = ReadLocalStorage(PERMISSIONS_KEY);
      if (permissionData) {
        const savedExpiryTime = permissionData.expiryTime;
        if (savedExpiryTime) {
          let expiryMoment = moment(savedExpiryTime);
          return moment().isAfter(expiryMoment);
        }
      }
      return true;
    } catch (error) {
      console.log("error", error);
      return true;
    }
  };

  useEffect(() => {
    // GetPlayerId();
    if (api.isUserAuthenticated() && checkExpiry()) {
      console.log("fetch permissions");
      GetPermissions();
      GetIntegrations();
      GetCalendarPermissions();
    }
  }, [location.pathname]);

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <DefaultLayout {...props} layout={layout}>
                  {route.element}
                </DefaultLayout>
              }
              key={idx}
            />
          ))}
        </Route>

        <Route>
          {authProtectedFlattenRoutes.map((route, idx) => {
            let _roleName = user1 ? user1.RoleName : user?.RoleName || "Admin";
            const userdata = user1 || user;
            if(userdata?.IsIndependent && (userdata.RoleName === USER_ROLE.USER || userdata.RoleName === USER_ROLE.VENDOR)){
              _roleName = `Independent-${user1?.RoleName || user?.RoleName}`
            }
            return <Route
              path={route.path}
              element={
                api.isUserAuthenticated() === false ? (
                  <Navigate
                    to={{
                      pathname: "auth/login",
                      search: "next=" + (route.path === "*" ? "/" : route.path),
                    }}
                  />
                ) : route.roles &&
                  route.roles.includes(_roleName) ? (
                  <Layout {...props}>{route.element}</Layout>
                ) : null
              }
              key={idx}
            />
          })}
        </Route>
        <Route path={"/a/:id"} element={<QRLanding />} />
        <Route path={"/vendor-register"} element={<VendorRegister />} />
        <Route path={"/sign-up"} element={<SignUp />} />
        <Route path={"/personal-sign-up"} element={<PersonalAccountSignup />} />
        <Route path={"/verify-otp"} element={<VerifyOTP />} />
        <Route path="/registered" element={<RegisterConfirm />} />
        {/* Snippet Paths */}
        <Route path={"/:key"} element={<SnippetContentPage />} />
        <Route path="/" element={<Root />} />
      </Routes>
    </React.Fragment>
  );
};

export default AllRoutes;
